// Roboto [300, 400, 500, 700] from Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
// Noto Sans JP [300, 400, 500, 700] from Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");

body {
  margin: 0;
  //background: #457fb3;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}

#app {
  height: 100%;
}

.znc {
  font-family: Roboto, "Noto Sans JP", sans-serif;
  th {
    background: rgba(#eff3f8, 0.5);
  }
  td {
    background: none;
  }
  th,
  td {
    border-color: rgba(#bac6cf, 0.5);
  }
  blockquote {
    color: unset;
    opacity: 0.8;
  }
  details[open] summary {
    background: rgba(#d5d6d8, 0.3);
  }
}
